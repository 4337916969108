import React from "react"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "@fontsource/open-sans"
import { QueryClient, QueryClientProvider } from "react-query"
const queryClient = new QueryClient()

export const wrapPageElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
)
