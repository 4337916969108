// example theme based on preset-future
import future from "@theme-ui/preset-funk"
import { merge } from "theme-ui"

export default merge(future, {
  fonts: {
    body: "Open Sans, sans-serif",
  },
  colors: {
    primary: "#44d62c",
    background: "#222",
    heading: "white",
    text: "#9b9d9f",
    discord: "#5865f2",
  },
  fontWeights: {
    heading: 500,
  },
  text: {
    heading: {
      color: "white",
    },
    paragraph: {
      my: 2,
    },
    footer: {
      variant: "paragraph",
      fontSize: 1,
    },
  },
  sizes: {
    container: 1200,
  },
  buttons: {
    primary: {
      cursor: "pointer",
      "&:disabled": {
        background: "#222",
      },
    },
    fullWidth: {
      cursor: "pointer",
      "&:disabled": {
        opacity: 0.4,
      },
      color: "background",
      width: "100%",
      fontWeight: "bold",
    },
    discord: {
      variant: "buttons.fullWidth",
      backgroundColor: "discord",
      color: "white",
    },
  },
  badges: {
    primary: {
      bg: "text",
      mx: 1,
    },
  },
})
